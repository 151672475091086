// src/components/PatientIntakeForm.js
import React, { useState, useEffect } from "react";
import "./Contact.css";
import { ClipLoader } from "react-spinners";
import Popup from "./Popup"; // Import the Popup component

const PatientIntakeForm = () => {
  // State variables for loading and form fields
  const [initialLoading, setInitialLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    dateOfBirth: "",
    phoneNumber: "",
    emailAddress: "",
    emergencyContactName: "",
    emergencyContactPhone: "",
    currentMedications: "",
    knownAllergies: "",
    mainConcerns: "",
    suicidalThoughts: "",
    selfHarm: "",
    consent: false,
  });

  // Function to toggle the popup visibility
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  // Simulate a loading delay for the initial page load
  useEffect(() => {
    const timer = setTimeout(() => setInitialLoading(false), 3000);
    return () => clearTimeout(timer);
  }, []);

  // Handlers for updating form fields
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Form submission handler
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    setIsSubmitting(true);

    // Simulate a form submission delay
    setTimeout(() => {
      setIsSubmitting(false);
      togglePopup(); // Open the popup upon form submission
    }, 3000);
  };

  return (
    <div className="contact-section">
      <div className="contact-form-container">
        <h2 className="form-title">Registration Form</h2>
        <p className="form-description">
          Fill out this form to provide the necessary information for your appointment.
        </p>
        {initialLoading || isSubmitting ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <ClipLoader color="#36d7b7" loading={initialLoading || isSubmitting} size={50} />
          </div>
        ) : (
          <form className="contact-form" onSubmit={handleSubmit}>
            <label htmlFor="name">Name *</label>
            <input
              type="text"
              value={formData.name}
              onChange={handleChange}
              id="name"
              name="name"
              required
            />

            <label htmlFor="dateOfBirth">Date of Birth *</label>
            <input
              type="date"
              value={formData.dateOfBirth}
              onChange={handleChange}
              id="dateOfBirth"
              name="dateOfBirth"
              required
            />

            <label htmlFor="phoneNumber">Phone Number *</label>
            <input
              type="tel"
              value={formData.phoneNumber}
              onChange={handleChange}
              id="phoneNumber"
              name="phoneNumber"
              required
            />

            <label htmlFor="emailAddress">Email Address *</label>
            <input
              type="email"
              value={formData.emailAddress}
              onChange={handleChange}
              id="emailAddress"
              name="emailAddress"
              required
            />

           

            <label htmlFor="currentMedications">List any medications you are taking currently *</label>
            <textarea
              id="currentMedications"
              value={formData.currentMedications}
              onChange={handleChange}
              name="currentMedications"
              rows="4"
              required
            ></textarea>

            <label htmlFor="suicidalThoughts">Are you having any suicidal thoughts? *</label>
            <div className="radio-group">
              <label>
                <input
                  type="radio"
                  name="suicidalThoughts"
                  value="yes"
                  checked={formData.suicidalThoughts === "yes"}
                  onChange={handleChange}
                  required
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name="suicidalThoughts"
                  value="no"
                  checked={formData.suicidalThoughts === "no"}
                  onChange={handleChange}
                  required
                />
                No
              </label>
            </div>

            <label htmlFor="mainConcerns">Have you been diagnosed with any psychiatric conditions in the past? If so, please list below. *</label>
            <textarea
              id="mainConcerns"
              value={formData.mainConcerns}
              onChange={handleChange}
              name="mainConcerns"
              rows="4"
              required
            ></textarea>

            <label htmlFor="selfHarm">Do you self-harm? *</label>
            <div className="radio-group">
              <label>
                <input
                  type="radio"
                  name="selfHarm"
                  value="yes"
                  checked={formData.selfHarm === "yes"}
                  onChange={handleChange}
                  required
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name="selfHarm"
                  value="no"
                  checked={formData.selfHarm === "no"}
                  onChange={handleChange}
                  required
                />
                No
              </label>
            </div>

            <div className="checkbox-container">
              <input
                type="checkbox"
                id="consent"
                name="consent"
                checked={formData.consent}
                onChange={handleChange}
                required
              />
              <label htmlFor="consent">I agree to participate in telepsychiatry sessions.</label>
            </div>

            <button type="submit" className="submit-button">
              Submit Details
            </button>
          </form>
        )}
        {isPopupOpen && <Popup togglePopup={togglePopup} />}
      </div>
    </div>
  );
};

export default PatientIntakeForm;
