import React, { useState } from 'react';
import './Hero.css'; // Make sure to create a Hero.css file for styles

const Hero = () => {
  // const [isOpen, setIsOpen] = useState(false);  // State to manage mobile menu visibility

  // const scrollToSection = (sectionId) => {
  //   const section = document.getElementById(sectionId);
  //   if (section) {
  //     section.scrollIntoView({ behavior: 'smooth' });
  //   }
  //   setIsOpen(false); // Optionally close the mobile menu on click
  // };
  return (
  <>
  <div id='hero'>
      <section className="hero">
      <div className="hero-content">
        <h1 className="hero-title"><br/> Where Healing Meets Convenience</h1>
       <p className="hero-subtitle">Virtual Psychiatry Services</p>
         {/* <button className="hero-button" onClick={() => scrollToSection('contact')}>Schedule a call with us</button> */}
      </div>
    </section>
    </div>
  </>
  );
};

export default Hero;
