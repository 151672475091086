import React, { useState } from "react";
import "./About.css";
import drsyeda from "../Assets/dr-syeda-img.jpeg";
import drsyed from "../Assets/dr-syed-quadri.jpeg";

const About = () => {
  const [isOpenSyeda, setIsOpenSyeda] = useState(false);
  const [isOpenSyed, setIsOpenSyed] = useState(false);

  const toggleSyeda = () => {
    setIsOpenSyeda(!isOpenSyeda);
  };

  const toggleSyed = () => {
    setIsOpenSyed(!isOpenSyed);
  };

  return (
    <div className="about">
      <h1 className="provider">Meet Our Providers</h1>

      <div className="about-container">
        <div className="about-dropdown" onClick={toggleSyeda}>
          <div className="dropdown-header">
            <img src={drsyeda} alt="Dr. Syeda Quadri" className="dropdown-img" />
            <span className="dropdown-name">Dr. Syeda Fatima Quadri</span>
            <span className="dropdown-arrow">{isOpenSyeda ? "▲" : "▼"}</span>
          </div>
          {isOpenSyeda && (
            <div className="dropdown-content">
              <div className="about-text">
                <div className="about-image">
                  <img src={drsyeda} alt="Dr. Syeda Quadri" />
                  <div className="image-caption">
                    <p>Dr. Syeda Fatima Quadri</p>
                    <p>MD (Psychiatry), MRCP (Psychiatry)</p>
                    <p>US Board Certified Psychiatrist</p>
                  </div>
                </div>
                <div className="text-content">
                  <h3 className="meetdrsyeda">
                    Dr. Syeda Fatima Quadri — Your Dedicated Psychiatrist at MindSpace
                  </h3>
                  <p>
                    With a rich educational background and extensive clinical experience, Dr. Quadri brings a depth of understanding and a compassionate approach to the treatment of various psychiatric conditions.
                  </p>
                  <h3>Expertise You Can Trust</h3>
                  <p>
                    Dr. Quadri's journey in medicine began at Dr. B.R. Ambedkar Medical College in Bangalore where she earned her Medical degree. She further honed her skills and knowledge in the field with an MRCPsych from the UK, followed by a comprehensive residency in General Adult Psychiatry at the KUSOM in Wichita, KS. This diverse international training equips Dr. Quadri with a unique perspective on mental health challenges and their treatments.
                  </p>
                  <h3>A Compassionate Approach to Care</h3>
                  <p>
                    Understanding that each individual’s journey to mental wellness is unique, Dr. Quadri provides personalized care tailored to each patient's needs. She has experience treating a wide array of psychiatric conditions.
                  </p>
                  <h3>A Commitment to Your Wellness</h3>
                  <p>
                    Dr. Quadri is deeply committed to the advancement of psychiatric care and continuously updates her practice with the latest research and evidence-based techniques. Her approach is not only to alleviate symptoms but also to empower her patients to achieve and maintain optimal mental health.
                  </p>
                  <h3>Schedule a Consultation</h3>
                  <p>
                    Whether you are facing new challenges or managing long-term issues, Dr. Quadri is here to support you. Schedule a virtual consultation with her today and take a significant step towards reclaiming your mental health and happiness. At MindSpace, your mental wellness journey is in caring hands.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="about-container">
        <div className="about-dropdown" onClick={toggleSyed}>
          <div className="dropdown-header">
            <img src={drsyed} alt="Dr. Syed Quadri" className="dropdown-img" />
            <span className="dropdown-name">Dr. Syed Quadri</span>
            <span className="dropdown-arrow">{isOpenSyed ? "▲" : "▼"}</span>
          </div>
          {isOpenSyed && (
            <div className="dropdown-content">
              <div className="about-text">
                <div className="about-image">
                  <img src={drsyed} alt="Dr. Syed Quadri" />
                  <div className="image-caption">
                    <p>Dr. Syed Quadri</p>
                    <p>MD (Psychiatry)</p>
                    <p>US Board Certified Psychiatrist</p>
                  </div>
                </div>
                <div className="text-content">
                  <h2 className="meetdrsyeda">Dr. Syed Quadri</h2>
                  <p>
                    Dr. Syed Quadri, M.D., provides compassionate and comprehensive psychiatric care to adults from age of 18 years and above. He specializes in managing depression, anxiety, bipolar disorder, OCD, PTSD and ADHD through evidence-based practices and personalized treatment plans.
                  </p>
                  <h3>Our reliable source for expert psychiatric care</h3>
                  <p>
                    Dr. Quadri earned his Bachelor of Medicine and Bachelor of Surgery from Dr. B.R. Ambedkar Medical College in Bangalore, India, and completed his psychiatry residency at the University of Kansas School of Medicine.
                  </p>
                  <h3>Empathetic support for your mental health journey</h3>
                  <p>
                    Understanding that each individual’s journey to mental wellness is unique, Dr. Quadri provides personalized care tailored to each patient's needs. He has experience treating a wide array of psychiatric conditions.
                  </p>
                  <h3>Passionate and Devoted to your mental health and happiness</h3>
                  <p>
                    Dr. Quadri is deeply committed to the advancement of psychiatric care and continuously updates his practice with the latest research and evidence-based techniques. His approach is not only to alleviate symptoms but also to empower his patients to achieve and maintain optimal mental health.
                  </p>
                  <h3>Schedule a Consultation</h3>
                  <p>
                    Whether you are facing new challenges or managing long-term issues, Dr. Quadri is here to support you. Schedule a virtual consultation with him today and take a significant step towards reclaiming your mental health and happiness. At MindSpace, your mental wellness journey is in caring hands.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default About;
