// src/components/Popup.js
import React from "react";
import "./Popup.css";

// Popup component
const Popup = ({ togglePopup }) => {
  return (
    <div className="popup">
      <div className="popup-content">
        <h3 style={{fontFamily:'sans-serif', color:'#fff', fontWeight:'bold'}}>Thank you for submitting your details</h3>
        <p style={{color:'#fff', fontFamily:'sans-serif',}}>you will hear from us very shortly .</p>
        {/* Button to close the popup */}
        <button onClick={togglePopup}>Close</button>
      </div>
    </div>
  );
};

export default Popup;
