import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Navbar from "./Components/Navbar";
import About from "./Components/About";
import Footer from "./Components/Footer";
import "./App.css";
import Hero from "./Components/Hero";
import WelcomePage from "./Components/WelcomePage";
import Contact from "./Components/Contact";
import NewPatientss from "./Components/NewPatientss";
import Service from "./Components/Service";
// import Service from "./Components/Service";
document.addEventListener('scroll', function() {
  const scrollUpButton = document.getElementById('scrollUpButton');
  if (window.scrollY > 20) {
    scrollUpButton.classList.add('visible');
  } else {
    scrollUpButton.classList.remove('visible');
  }
});



const App = () => {
  return (
    <>
      <Navbar />
      <Hero/>
      <WelcomePage />
      <div id="about">
        <About />
      </div>

      {/* <div id="services">
        <Service />
      </div> */}
      <div id="services">
        <Service />
      </div>

      {/* Add a "Back" button */}
      <div id="newPatients">
        <NewPatientss />
      </div>

      <div id="contact">
        <Contact />
      </div>

      <Footer />

      <a href="#" class="scroll-up" id="scrollUpButton">Scroll Up</a>
    </>
  );
};

export default App;
