import React from 'react';
import './NewPatientss.css';

const NewPatientss = () => {
  return (
    <div id="NewPatients">
    <div className="newPatients">
      <p className="xyza">
        We are accepting new patients 18 years of age and older in Michigan and California.
      </p>
      <p className="xyza">
        Currently, we do not accept any insurance plans and employee assistance programs (EAP) of any kind. We will provide a superbill.
      </p>
      <p className="xyza">
        Please fill out the patient intake form to get an appointment.
      </p>
    </div>
    </div>
  );
}

export default NewPatientss;
