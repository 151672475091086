import React from 'react';
import './WelcomePage.css'; // Ensure this path is correct

const WelcomePage = () => {
  return (
    <div className="welcome-container">
       <div className="home">
      <h1>Welcome to MindSpace — Where Healing Meets Convenience</h1>
      {/* <p>
        At MindSpace, we believe that mental health support should be accessible, personal, and tailored to fit your life. Our virtual psychiatry services connect you with professional care wherever you are, whenever you need it. Because when it comes to mental health, every moment counts.
      </p> */}
      {/* <h2>Personalized Psychiatry at Your Fingertips</h2> */}
      <p>
        Explore a comprehensive approach to mental wellness with MindSpace. Our team of licensed psychiatrists and mental health professionals is dedicated to providing you with high-quality, confidential, and compassionate care tailored to your unique needs—all from the comfort of your own space.
      </p>
      </div>
    </div>
  )
}

export default WelcomePage;

