import React, { useState } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import mindspacelogo from '../Assets/mindspacelogo.jpeg';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);  // State to manage mobile menu visibility

  // Function to toggle mobile menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Function to handle smooth scrolling to sections
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    setIsOpen(false); // Optionally close the mobile menu on click
  };

  return (
    <div className="navbar">
      <div className="logo">
        <img 
          className="mindspacelogo" 
          src={mindspacelogo} 
          alt="TMS Mindspace Logo" 
          style={{ width: '80px', height: '80px', borderRadius: '50px' }} 
        />
        <Link to="/" ><h1 className='mindspacename'>MINDSPACE</h1></Link>
        <div className="toggle_btn" onClick={toggleMenu}>
          <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
        </div>
      </div>
      <ul className={isOpen ? "links open" : "links"}>
        <li><a href="#about" onClick={() => scrollToSection('about')}>About Us</a></li>
        <li><a href="#services" onClick={() => scrollToSection('services')}>Services</a></li>
        <li><a href="#NewPatients" onClick={() => scrollToSection('NewPatients')}>New Patients</a></li>
        <li><a href="#contact" onClick={() => scrollToSection('contact')}>Contact Us</a></li>
      </ul>
    </div>
  );
};

export default Navbar;
