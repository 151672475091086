import React from 'react';
import './Service.css';

const services = [
  {
    name: 'Major Depressive Disorder',
    symptoms: ['Low mood', 'Anhedonia which is loss of pleasure in daily activities', 'Changes in sleep and appetite', 'Lack of motivation', 'Low energy levels', 'Poor concentration', 'Suicidal thoughts', 'Feelings of inappropriate guilt', 'Hopelessness and worthlessness']
  },
  {
    name: 'Anxiety Disorder',
    symptoms: ['Excessive worry', 'Restlessness', 'Muscle tension', 'Irritability']
  },
  {
    name: 'Obsessive Compulsive Disorder',
    symptoms: ['Recurrent thoughts', 'Repetitive behaviors', 'Anxiety', 'Compulsive actions']
  },
  {
    name: 'Panic Disorder',
    symptoms: ['Sudden panic attacks', 'Chest pain', 'Shortness of breath', 'Fear of losing control']
  },
  {
    name: 'Mood Disorder',
    symptoms: ['Mood swings', 'Irritability', 'Mania', 'Depression']
  },
  {
    name: 'Post-Traumatic Stress Disorder',
    symptoms: ['Flashbacks', 'Nightmares', 'Severe anxiety', 'Avoidance']
  }
];

const Service = () => {
  return (
    <div id='services'>
      {services.map((service, index) => (
        <div key={index} className='service-box'>
          <h3>{service.name}</h3>
          <ul>
            {service.symptoms.map((symptom, idx) => (
              <li key={idx}>{symptom}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default Service;
