import React, { useState } from 'react';

import './Footer.css'; // Make sure the path to your CSS file is correct
import { WhatsApp } from '@mui/icons-material'; // Import WhatsApp icon
import { Link } from 'react-router-dom';


const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);  // State to manage mobile menu visibility

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    setIsOpen(false); // Optionally close the mobile menu on click
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section about">
          
          
          {/* <button onClick={() => scrollToSection('contact')}>Schedule call with us</button> */}
        </div>
        <div className="linksfooter">
          <h4>Quick Links</h4>
          <ul className="linksfooter">
        <li><Link to="#hero" onClick={() => scrollToSection('hero')}>Home</Link></li>
        <li><Link to="#services" onClick={() => scrollToSection('servicessection')}>Services</Link></li>
        <li><Link to="#about" onClick={() => scrollToSection('about')}>About Us</Link></li>
        <li><Link to="#contact" onClick={() => scrollToSection('contact')}>Contact Us</Link></li>
      </ul>
        </div>
      
        
        
        
        <div className="footer-section hours">
          <h4>Availability</h4>
          <hr/>
          
          <h5>Michigan : <p> Monday - Sunday : 1 pm to 9 pm - (EST)</p></h5>
          <hr/>
          
          <h5>California : <p>Monday - Sunday : 10 am to 7 pm -(PST)</p></h5>
          <hr/>



        </div>
      </div>
   

      <div className="footer-bottom">
        <p><i>Mindspace health services @ 2024 All rights reserved</i></p>
        

    

      </div>
    </footer>
  );
};

export default Footer;
